import React from 'react'
import Mes from "../img/measure.PNG";
import Surv from "../img/survey.PNG";
import { useNavigate } from 'react-router-dom';


export const Home = () => {
  const navigate = useNavigate();
  return (
<>
  <div className='section cc-store-home-wrap'>
    <div className="intro-header">
      <div className="intro-content cc-homepage">
        <div className="intro-text">
          <div className="heading-jumbo">
            Change how you see data.
          </div>
          <div className="paragraph-bigger">
            Derive meaningful insights and drive results.
          </div>
        </div>
        <a href="/contact" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
          <div>
            Learn More
          </div>
        </a>
      </div>
    </div>
    <div className='container'>
      <div className='motto-wrap'>
        <div className='label cc-light'>
          <div className="label cc-light">
            What I believe in
          </div>
          <div className="heading-jumbo-small">
          Data should be more than just numbers on a page.
          Making a Powerbi dashboard drastically improves comprehension 
          and strategic planning capabilities. 
        </div>
      </div>
      </div>
      <div className="divider"></div>
      <div className="home-content-wrap">
        <div className="w-layout-grid about-grid">
          <div >
            <div cclassNameass="home-section-wrap">
              <div className="label cc-light">Alberta Education</div>
              <h2 className="section-heading">AEAM Reporting</h2>
              <p className="paragraph-light">
                Take the couple hundred page AEAM PDF report you get every year and
                turn it into an interactive dashboard you can show to shareholders.
              </p>
            </div>
          
              <a href="/contact" className="button w-inline-block">
                <div>Learn More</div>
              </a>
          </div>
          <img src={Mes} />
        </div>
        <div className="w-layout-grid about-grid-2">
        <img className='bottom' src={Surv} />
          <div className='top'>
            <div className="home-section-wrap">
              <div className="label cc-light">Alberta Education</div>
              <h2 className="section-heading">Survey Results</h2>
              <p className="paragraph-light">
                The next step after seeing the summerized results is to 
                drill down one step further. View the survey results by 
                group and school to give your team the information they 
                need to be set for success.
              </p>
            </div>
              <a href="/contact" className="button w-inline-block">
                <div>Learn More</div>
              </a>
          </div>
          
        </div>
      </div>
      
    </div>
  </div>
</>
  )
}
