import React from 'react'


export const Contact = () => {

  return (
  <div className="section cc-home-wrap">
    
    <div class="intro-header cc-subpage">
          <div class="intro-content">
            <div class="heading-jumbo">
              Let's Talk 
            </div>
          </div>
        </div>
        <div class="container">
          <div class="motto-wrap">
            <div class="heading-jumbo-small">
              To learn more contact us today! Set up a meeting and
              do a live demo, or consult you on your data needs.
            </div>
          </div>
          <div class="divider"></div>
        </div>
      <h2 style={{textAlign:"center",paddingBottom:"10px"}}>Contact Information</h2>
      <p style={{textAlign:"center",paddingBottom:"10%"}}>contact@nickconsults.ca</p>
    <div>

    </div> 
  </div>

  )
}
