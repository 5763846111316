import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../img/logo192.png";


export const Header = () => {

  const navigate = useNavigate();

  return (
  <>      
    <header className='header' >
      <img className='Logo' src={Logo} alt="" onClick={()=>navigate('/')} />
      <p className='title sm' onClick={()=>navigate('/')} >NNConsulting</p>
      <p className='title' onClick={()=>navigate('/about')} >About</p>
      <p className='title' onClick={()=>navigate('/contact')} >Contact</p>
    </header>
    
  </>  

  )
}
