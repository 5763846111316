/*Router/Auth Stuff */
import {Routes, Route} from "react-router-dom"
/*Pages */
import { Home} from "./pages/Home";
import { About } from "./pages/About";
/*Header */
import { Header } from "./components/Header";
/*CSS */
import './pages/main.css'
import './pages/home.css'
import './pages/about.css'
import { Contact } from "./pages/Contact";


function App() {

  return (  
    <>  


        <div className="siteGrid">
            <div>
              <Header/> 
            </div>
          <div >
            <Routes>
              <Route path='*' element={<Home/>} />
              <Route path='/' element={<Home/>} />
              <Route path='/contact' element={<Contact/>} />
              <Route path='/about' element={<About/>} />
            </Routes>
          </div>
        </div>
        
    </>
  )    

}
export default App;
