import {ReactComponent as Charts} from "../img/charts.svg";
import {ReactComponent as Edu} from "../img/edu.svg";
import {ReactComponent as Mail} from "../img/mail.svg";
import { useNavigate } from 'react-router-dom';

export const About = () => {
  const navigate = useNavigate();


  return (
    
      <div className="section cc-home-wrap">
        <div class="intro-header cc-subpage">
          <div class="intro-content">
            <div class="heading-jumbo">
              About 
            </div>
          </div>
        </div>
        <h2 className="aboutHR">About Us </h2>

        <div className="aboutCE">
          <Charts className="aboutSvg"/>
          <p className="aboutTC">  
           I am Nicholas Nowosad an Electronics Engineering Technologist by trade but the past couple years my focus has been on data analysis and visualization in the education space.
            My main goal is to provide quick and seamless data visualization for strategic planning and assurance reporting.
            That's not all I do though if you need data visualization services I can get your data from storage to Powerbi in no time.

          </p>
        </div>
        <div className="divider"></div>
        <div style={{paddingTop:"2rem"}}>
          <h2 className="aboutHL">Education Services</h2>
          <div className="aboutCS">
            <p className="aboutTC">
              Currently for education reports I have data models for AEAM reporting, Diploma and PAT performance enrollment trends / demographics and finance available.
              I am not limited to what I have available though if you have data and want to set up a pipe line for years to come I can do that.

            </p>
            <Edu className="aboutSvg"/>
          </div>
        </div>

        <div className="divider"></div>
        <div style={{paddingTop:"2rem"}}>
          <h2 className="aboutHR Hov" onClick={()=>navigate('/contact')}>Get Started Today</h2>
          <div className="aboutCE">
          <Mail className="aboutSvg Hov" onClick={()=>navigate('/contact')}/>
            <p className="aboutTC ">
              I take on jobs of all sizes big and small. Get in touch today and 
              we can setup a preliminary meeting and go from there.
            </p>
          </div>
        </div>


      </div>



  
  )

}
